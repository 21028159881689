import type { DetailedProjectRepository } from '@/project/domain/repositories/DetailedProject.repository'
import { DetailedProject } from '@/project/domain/entities/DetailedProject'
import type { BiomeProjectId, FisProjectId } from '@/shared/domain/Ids'
import StorageService, { NoItemFound } from '@/shared/infrastructure/services/Storage.service'
import type BackofficeGateway from '@/shared/infrastructure/gateways/Backoffice.gateway'
import type { FisHttpClient } from '@/shared/infrastructure/gateways/Backoffice.gateway'
import AuthenticationService from '@/authentication/infrastructure/services/Authentication.service'
import { NoResult } from '@/shared/infrastructure/gateways/Fetch.gateway'
import PolygonService from '@/project/infrastructure/services/PolygonService'
import type CarbonSimulatorGateway from '@/shared/infrastructure/gateways/CarbonSimulator.gateway'
import type { CarbonSimulatorScenarioApiResponse } from '@/shared/infrastructure/gateways/CarbonSimulator.gateway'
import type { MonolithGateway } from '@/shared/infrastructure/gateways/monolith.gateway'
import { DetailedProjectFactory } from '@/project/infrastructure/repositories/DetailedProject.factory'
import type BackendGateway from '@/project/infrastructure/gateways/Backend.gateway'
import { type BeneficiariesApiResults } from '@/project/infrastructure/gateways/Backend.gateway'
import type { RemoteSensingGateway } from '@/project/infrastructure/gateways/RemoteSensing.gateway'
import { useProjectStore } from '@/contributor/domain/stores/project.store'
import { container } from '@/shared/container'

export class DetailedProjectHttpRepository implements DetailedProjectRepository {
  private readonly CACHE_KEY = 'project'

  public constructor(
    private readonly storageService: StorageService,
    private readonly backofficeGateway: BackofficeGateway,
    private readonly authenticationService: AuthenticationService,
    private readonly polygonService: PolygonService,
    private readonly carbonSimulatorGateway: CarbonSimulatorGateway,
    private readonly monolithGateway: MonolithGateway,
    private readonly remoteSensingGateway: RemoteSensingGateway,
    private readonly backendGateway: BackendGateway,
  ) {}

  public async loadProject(
    projectId: BiomeProjectId,
    fisId: FisProjectId,
  ): Promise<DetailedProject> {
    const accessToken = await this.authenticationService.getAccessToken()
    const projectStore = useProjectStore()
    const project = await this.backofficeGateway.getProject(accessToken.token, projectId)
    if (project instanceof NoResult) throw new Error(`Could not get project ${projectId}`)

    const reportings = await this.backendGateway.getReports(project.idBiome)
    if (reportings instanceof NoResult)
      console.error(`Could not get project reportings for ${projectId}`)

    const polygons = await this.polygonService.getPolygons(project.id)
    if (polygons instanceof NoResult)
      throw new Error(`Could not get polygons for project ${projectId}`)

    const client = this.storageService.get<FisHttpClient>('client')
    if (client instanceof NoItemFound) throw new Error('Could not get client from storage')

    const clientMedias = await container.backofficeGateway.getMedias(accessToken.token, fisId)
    if (clientMedias instanceof Error) throw new Error('Could not get client medias')

    const carbonSimulations = await this.getProjectCarbonSimulations(projectId)
    const carbonMetrics = await this.backendGateway.getMetrics(fisId, ['carbon'])
    const biodiversitySimulations =
      await this.monolithGateway.getProjectsBiodiversitySimulationResults([fisId])
    if (biodiversitySimulations instanceof NoResult) console.log(biodiversitySimulations.message)

    const remoteSensingIndexesResult = await this.remoteSensingGateway.getIndexes(fisId)
    if (remoteSensingIndexesResult instanceof NoResult) {
      console.log(remoteSensingIndexesResult.message)
    }

    projectStore.contributor = {
      client: {
        webName: client.webName,
        name: client.name,
      },
      clientMedias,
    }

    const detailedProject = DetailedProjectFactory.toDetailedProject(
      projectId,
      project.id,
      client,
      project,
      carbonSimulations.first(),
      biodiversitySimulations instanceof NoResult ? [] : biodiversitySimulations,
      polygons,
      remoteSensingIndexesResult instanceof NoResult ? null : remoteSensingIndexesResult,
      carbonMetrics.carbon,
      reportings instanceof NoResult ? null : reportings.reports,
    )
    this.storageService.set(this.CACHE_KEY, detailedProject)

    return detailedProject
  }

  private async getProjectCarbonSimulations(
    projectId: BiomeProjectId,
  ): Promise<CarbonSimulatorScenarioApiResponse[]> {
    const simulation = await this.carbonSimulatorGateway.getValidatedProjects([projectId])
    if (simulation instanceof NoResult) throw new Error('Could not get projects simulations')

    const projectSimulations = simulation.results.filter((r) => r.id === projectId)
    const scenari: CarbonSimulatorScenarioApiResponse[] = []
    for await (const simulation of projectSimulations) {
      const scenario = await this.carbonSimulatorGateway.getScenario(simulation._id)
      if (!(scenario instanceof NoResult)) scenari.push(scenario)
    }

    return scenari
  }

  public getProject(): DetailedProject {
    const rawProject = this.storageService.get<DetailedProject>(this.CACHE_KEY)
    if (rawProject instanceof NoItemFound) throw rawProject
    return DetailedProject.hydrate(rawProject)
  }

  public async getBeneficiaries(
    projectId: FisProjectId,
  ): Promise<BeneficiariesApiResults | NoResult> {
    const accessToken = await this.authenticationService.getAccessToken()
    return await this.backendGateway.getBeneficiaries(accessToken.token, projectId)
  }
}
