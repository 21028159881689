import AuthenticationService from '@/authentication/infrastructure/services/Authentication.service'
import StorageService from '@/shared/infrastructure/services/Storage.service'
import BackofficeHttpgateway from '@/shared/infrastructure/gateways/Backoffice.httpgateway'
import BackofficeFakegateway from '@/shared/infrastructure/gateways/Backoffice.fakegateway'
import CarbonSimulatorFakeGateway from '@/shared/infrastructure/gateways/CarbonSimulator.fakegateway'
import CarbonSimulatorHttpGateway from '@/shared/infrastructure/gateways/CarbonSimulator.httpgateway'
import WhoAmIPresenter from '@/contributor/presentation/presenters/WhoAmIPresenter'
import PolygonService from '@/project/infrastructure/services/PolygonService'
import HowManyCarbonISequesteredOverThirtyYearsPresenter from '@/project/presentation/presenters/HowManyCarbonISequesteredOverThirtyYears.presenter'
import env from '@/shared/env'
import ContributorHttpRepository from '@/contributor/infrastructure/repositories/Contributor.httprepository'
import { DetailedProjectHttpRepository } from '@/project/infrastructure/repositories/DetailedProject.httprepository'
import { MonolithHttpGateway } from '@/shared/infrastructure/gateways/monolith.httpgateway'
import WhereICanSeeMyAnnualReportPresenter from '@/contributor/presentation/presenters/WhereICanSeeMyAnnualReport.presenter'
import WhatIsMyBiodiversityImpactOverThirtyYearsPresenter from '@/project/presentation/presenters/WhatIsMyBiodiversityImpactOverThirtyYears.presenter'
import { MonolithFakeGateway } from '@/shared/infrastructure/gateways/monolith.fakegateway'
import RemoteSensingHttpgateway from '@/project/infrastructure/gateways/RemoteSensing.httpgateway'
import BackendHttpGateway from '@/project/infrastructure/gateways/Backend.httpgateway'
import BackendFakeGateway from '@/project/infrastructure/gateways/Backend.fakegateway'

const storageService = new StorageService()
const backofficeGateway = env.isMock
  ? new BackofficeFakegateway()
  : new BackofficeHttpgateway(env.backoffice.url)

const authenticationService = new AuthenticationService(storageService, backofficeGateway)

const backendGateway = env.isMock
  ? new BackendFakeGateway()
  : new BackendHttpGateway(env.backend.url, authenticationService)

const carbonSimulatorGateway = env.isMock
  ? new CarbonSimulatorFakeGateway()
  : new CarbonSimulatorHttpGateway(env.carbonSimulator.url)

const monolithGateway = env.isMock
  ? new MonolithFakeGateway()
  : new MonolithHttpGateway(env.backend.url, authenticationService)

const polygonService = new PolygonService(backofficeGateway, storageService)

const remoteSensingGateway = new RemoteSensingHttpgateway(env.remoteSensing.url)

const contributorRepository = new ContributorHttpRepository(
  storageService,
  authenticationService,
  backofficeGateway,
  carbonSimulatorGateway,
  monolithGateway,
)

const projectRepository = new DetailedProjectHttpRepository(
  storageService,
  backofficeGateway,
  authenticationService,
  polygonService,
  carbonSimulatorGateway,
  monolithGateway,
  remoteSensingGateway,
  backendGateway,
)

export const container = {
  storageService,
  backofficeGateway,
  contributorRepository,
  projectRepository,
  authenticationService,
  whoAmIPresenter: new WhoAmIPresenter(contributorRepository),
  howManyCarbonISequesteredOverThirtyYearsPresenter:
    new HowManyCarbonISequesteredOverThirtyYearsPresenter(projectRepository),
  whereICanSeeMyAnnualReportPresenter: new WhereICanSeeMyAnnualReportPresenter(storageService),
  whatIsMyBiodiversityImpactOverThirtyYearsPresenter:
    new WhatIsMyBiodiversityImpactOverThirtyYearsPresenter(projectRepository),
}
