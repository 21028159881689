import { createApp } from 'vue'
import '@ds/styles/index.css'
import '../public/assets/main.css'
import './shared/override'
// Problème : les maps Leaflets sont cassées quand on fait ctrl + f5
// Solution : il faut charger le css en début de fichier avant de charger leaflet, peut-être à cause du <style scoped>
// https://stackoverflow.com/questions/56364129/vue2-leaflet-map-not-showing-properly-in-boostrapvue-modal
import 'leaflet/dist/leaflet.css'
import * as Sentry from '@sentry/vue'
import App from './App.vue'
import router from './shared/infrastructure/router'
import env from '@/shared/env'
import { i18n } from '@/locales/i18n'
import 'tippy.js/dist/tippy.css'
import VueTippy from 'vue-tippy'
import { createPinia } from 'pinia'

const app = createApp(App)
const pinia = createPinia()

if (env.isProd && !env.isLocalhost) {
  Sentry.init({ app, dsn: env.sentry.url })
}

app.use(router)
app.use(pinia)
app.use(i18n)
app.use(VueTippy, {
  component: 'tippy',
  defaultProps: {
    placement: 'bottom',
    allowHTML: false,
  },
})

app.mount('#app')
